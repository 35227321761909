import React, { useEffect, useState } from 'react'
import { Facebook, Twitter, MessageCircle, Mail } from 'react-feather'
import './ShareLinks.css'

export default function ShareLinks({ title, url }) {
  function isMobileOrTablet() {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent)
  }

  function createShareUrl(title, url, type) {
    let shareUrl = ''
    switch (type) {
      case 1:
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
        break
      case 2:
        shareUrl = `https://twitter.com/intent/tweet?text=${title}&url=${url}`
        break
      case 3:
        shareUrl = isMobileOrTablet()
          ? `whatsapp://send?text=${url}`
          : `https://web.whatsapp.com/send?text=${url}`
        break
      case 4:
        shareUrl = `mailto:?&subject=${title}&body=${url}`
        break
      default:
        break
    }
    const encodedUrl = encodeURI(shareUrl)
    return encodedUrl
  }

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  },[])

  return (
    <div>
      {isMounted ? (
        <div className="share-container">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={createShareUrl(title, url, 1)}
            title="Share via Facebook"
          >
            <Facebook color="#507cbe" />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href={createShareUrl(title, url, 2)}
            title="Share via Twitter"
          >
            <Twitter color="#63cdf1" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={createShareUrl(title, url, 3)}
            data-action="share/whatsapp/share"
            title="Share via WhatsApp"
          >
            <MessageCircle color="#00ad82" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={createShareUrl(title, url, 4)}
            title="Share via Email"
          >
            <Mail color="#f16261" />
          </a>
        </div>
      ) : null}
    </div>
  )
}
